import { useEffect, useState } from 'react';
import Button from '../../../../components/Form/Button';
import Modal from '../../../../components/Modal';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  ManualSelectionConfirmationModalProps,
  RecommendedMotors,
} from '../models/MotorSelectionModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import Icon from '../../../../components/Icon';

const ManualSelectionConfirmationModal = ({
  modalOpen,
  onClose,
  type,
  onSelectClick,
}: ManualSelectionConfirmationModalProps) => {
  const { t } = useTranslate();
  const [isMotorManuallySelected, setIsMotorManuallySelected] = useState(false);
  const [motorDetailsSelected, setMotorDetailsSelected] = useState<
    RecommendedMotors | undefined
  >(undefined);

  const dimensionInput = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails.dimensionInputData,
  );

  useEffect(() => {
    if (isMotorManuallySelected) {
      onSelectClick(
        dimensionInput?.selectionInputs?.[0]?.dataCategory,
        motorDetailsSelected?.index ?? '',
      );
      setIsMotorManuallySelected(false);
    }
  }, [isMotorManuallySelected]);

  const handleSelectClick = () => {
    setIsMotorManuallySelected(true);
    onClose();
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onModalClose={onClose}
        showCloseButton={false}
        type="default"
        showHederSeparator="false"
        show-footer-separator="true"
        backdropClick={false}
        className="manual-selection-confirmation-modal"
        modalTitle={`Change ${type}?`}
      >
        <Icon
          size="small"
          name="drive"
          className="drive-icon"
          slot="title-icon"
        />
        <div>
          <div
            id="confirmation-modal-content"
            className="confirmation-modal-content modal-container"
            data-rf="confirmation-modal-content"
          >
            <div data-rf="confirmation-modal-description">
              {`You have selected a new ${type}, which can affect selection of its connected units.`}
            </div>
            <div className="confirmation-modal-button-container">
              <div className="cancel-button-container">
                <Button
                  type="discreet-black"
                  size="small"
                  onClick={onClose}
                  className="cancel-button"
                  data-rf="confirmation-modal-cancel-button"
                >
                  {t(I18.modal_cancel_button)}
                </Button>
              </div>
              <div className="select-button-container">
                <Button
                  type="primary-black"
                  size="small"
                  onClick={handleSelectClick}
                  className="confirmation-modal-select-button"
                  data-rf="confirmation-modal-select-button"
                >
                  {`Select ${type}`}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ManualSelectionConfirmationModal;
