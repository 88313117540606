import Button from '../../../../components/Form/Button';
import Icon from '../../../../components/Icon';
import {
  CurrentCurves,
  MotorSelectionResultProp,
  PowerCurves,
  TorqueCurves,
} from '../models/MotorSelectionModels';
import TabGroup from '../../../../components/TabGroup';
import TabItem from '../../../../components/TabItem';
import TabContent from '../../../../components/TabContent';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { generateKey } from '../../../../utils/GeneralUtils';
import { useTranslate } from '../../../../i18translate/Hooks';
import { I18 } from '../../../../languages/I18';
import {
  motorTableData,
  selectionTableData,
  vsdRatingTableData,
} from '../models/MotorDefaultValues';
import ReactApexCharts from 'react-apexcharts';
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import {
  dimensionGraphOptions,
  dimensionTorqueOptions,
} from '../../../../utils/GraphDefaultData';
import RadioGroup from '../../../../components/Form/RadioGroup';
import Radio from '../../../../components/Form/Radio';
import MotorCatalogueData from './MotorCatalogueData';
import {
  addEfficiencyTab,
  setHasRetryCatalogDataSelected,
  setShowPowerCurve,
} from '../../store/WorkspaceReducer';
import ResultGraphModalMotor from './ResultGraphModalMotor';
import ErrorAndWarningDialog from './ErrorAndWarningDialog';
import CommonLoader from '../../../../components/Loader';

const MotorSelectionResult = ({
  isLoading,
  isError,
}: MotorSelectionResultProp) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [optionsRef, setOptionsRef] = useState<ApexOptions | null>(null);
  const [efficiencyDisabled, setEfficiencyDisabled] = useState(true);
  const [expandGraph, setExpandGraph] = useState(false);

  const allData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.selectMotorResult,
  );

  const errorData = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.errorOrWarningMotorResponse,
  );

  const showPowerCurve = useSelector(
    (state: RootState) =>
      state.workspace.applicationDetails[state.workspace.currentAppRank]
        .dimensionDetails?.showPowerCurve,
  );

  useEffect(() => {
    setOptionsRef(
      showPowerCurve ? dimensionGraphOptions : dimensionTorqueOptions,
    );
  }, [showPowerCurve]);

  const dimensionData = useSelector((state: RootState) => {
    return state.workspace.applicationDetails[state.workspace.currentAppRank]
      ?.dimensionDetails;
  });
  let result = undefined;
  let catalogueTableData = undefined;
  let selectionData = undefined;
  let vsdRatingData = undefined;

  let powerCurves: PowerCurves | undefined;
  let torqueCurves: TorqueCurves | undefined;
  let currentCurves: CurrentCurves | undefined;
  result =
    allData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit;

  if (result?.results) {
    catalogueTableData = result?.results;

    selectionData =
      result?.miscellaneousUnit?.selectionData ?? selectionTableData;

    vsdRatingData = result?.miscellaneousUnit?.vSDRatings ?? vsdRatingTableData;

    if (!isLoading) {
      powerCurves = result?.miscellaneousUnit?.powerGraph;
      torqueCurves = result?.miscellaneousUnit?.torqueGraph;
      currentCurves =
        allData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.inverterUnit
          ?.miscellaneousUnit?.currentGraph;
    }
  } else {
    catalogueTableData = motorTableData;
    selectionData = selectionTableData;
    vsdRatingData = vsdRatingTableData;
  }

  useEffect(() => {
    if (
      Object.keys(dimensionData?.dimensionOutputData)?.length > 0 &&
      dimensionData?.dimensionOutputData?.supplyDriveLoads?.[0]?.motorUnit
    ) {
      setEfficiencyDisabled(false);
    }
  }, [dimensionData.dimensionOutputData, dimensionData.dimensionDataLoading]);

  useEffect(() => {
    if (
      !!allData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
        ?.results?.[0]?.value
    ) {
      setEfficiencyDisabled(false);
    }
  }, [allData]);

  const handleRadioButtonClick = (event: any) => {
    if (event.detail.value === 'power') {
      dispatch(setShowPowerCurve(true));
    } else {
      dispatch(setShowPowerCurve(false));
    }
  };

  const onEfficiencyButtonClick = () => {
    dispatch(addEfficiencyTab(true));
  };

  const handleExpandGraphClick = () => {
    setExpandGraph(true);
  };

  const onResultGraphModalClose = () => {
    setExpandGraph(false);
  };

  const motorTypecode =
    allData?.dimension?.dimensionResult?.supplyDriveLoads?.[0]?.motorUnit
      ?.results?.[0]?.value;

  return (
    <>
      {isLoading && (
        <div className="motor-result-loader-container">
          <div>
            <CommonLoader
              size="small"
              indicatorType={'circular'}
              progressType={'indeterminate'}
              progressValue={50}
              type={'primary-black'}
            />
          </div>
          <div className="motor-result-loader-message">
            {'Loading motor data...'}
          </div>
        </div>
      )}
      {!isLoading && isError && (
        <div>
          <ErrorAndWarningDialog
            isError={true}
            isWarning={false}
            data={'Failed to load some motor data. Please retry after sometime'}
          />
          <div className="button-retry">
            <Button
              type="secondary"
              onClick={() => {
                dispatch(setHasRetryCatalogDataSelected(true));
              }}
            >
              {
                <Icon
                  slot="icon"
                  size="small"
                  name="synchronize"
                  theme="dark"
                />
              }
              {'Retry'}
            </Button>
          </div>
        </div>
      )}
      {!isError &&
        motorTypecode &&
        allData?.dimension?.catalogueDataMotor &&
        Object.keys(allData?.dimension?.catalogueDataMotor.productAttributes)
          .length === 0 && (
          <div className="dimension-info-notification">
            <img className="transformer-info-icon" src="/infoblue.svg" />
            <div>Some data is not available for this motor</div>
          </div>
        )}
      <div className="right-header">
        <div className="right-header-text">
          {t(I18.motor_selection_result_title)}
        </div>
        <div className="right-header-button-container">
          <Button
            type="primary-black"
            disabled={efficiencyDisabled || isLoading}
            onClick={() => onEfficiencyButtonClick()}
          >
            <div className="efficiency-button">
              {t(I18.motor_selection_result_efficiency_button)}
            </div>
          </Button>
        </div>
      </div>
      {errorData?.isError ||
      (!Boolean(motorTypecode) &&
        !errorData?.isWarning &&
        errorData?.isError) ||
      (!Boolean(motorTypecode) &&
        errorData?.isWarning &&
        !errorData?.isError) ? (
        <div className="testttt">
          <ErrorAndWarningDialog
            isError={errorData?.isError ?? motorTypecode === ''}
            isWarning={errorData?.isWarning}
            data={
              errorData?.errorMotorSelection ??
              (motorTypecode === ''
                ? 'Motor selection failed because there is no motor which meets the requirements'
                : null)
            }
          />
        </div>
      ) : (
        <div>
          {errorData?.isWarning && Boolean(motorTypecode) && (
            <div>
              <ErrorAndWarningDialog
                isError={errorData?.isError}
                isWarning={errorData?.isWarning}
                data={errorData?.errorMotorSelection}
              />
            </div>
          )}

          <div>
            <div className="subtitle-container" slot="left">
              <div className="subtitle-header">
                {t(I18.motor_selection_result_subtitle)}
              </div>
            </div>
            <div className="type-product-info-table-container">
              <table className="type-product-info-table">
                <thead>
                  <tr>
                    <th className="type-product-info-table-header"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className="type-product-row"
                    key={generateKey('Type Code')}
                  >
                    <td className="type-product-col">
                      {t(I18.motor_selection_result_typecode_label)}
                    </td>
                    <td className="type-product-col value-col">
                      {isLoading ? (
                        <div className="catalogue-commonLoader">
                          <CommonLoader
                            size="xsmall"
                            indicatorType={'circular'}
                            progressType={'indeterminate'}
                            progressValue={0}
                            type={'primary-blue'}
                          />
                        </div>
                      ) : result?.results ? (
                        (result?.results?.[0]?.value ?? '-')
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                  <tr
                    className="type-product-row"
                    key={generateKey('Product Code')}
                  >
                    <td className="type-product-col">
                      {t(I18.motor_selection_result_productcode_label)}
                    </td>
                    <td className="type-product-col value-col">
                      {isLoading ? (
                        <div className="catalogue-commonLoader">
                          <CommonLoader
                            size="xsmall"
                            indicatorType={'circular'}
                            progressType={'indeterminate'}
                            progressValue={0}
                            type={'primary-blue'}
                          />
                        </div>
                      ) : result?.results ? (
                        (result?.results?.[1]?.value ?? '-')
                      ) : (
                        '-'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              {!isLoading && (
                <div>
                  <div className="img-and-desc-container">
                    {allData?.dimension?.catalogueDataMotor?.productImages &&
                      allData?.dimension?.catalogueDataMotor?.productImages
                        ?.length > 0 && (
                        <div className="img-container">
                          <img
                            src={
                              allData?.dimension?.catalogueDataMotor
                                ?.productImages &&
                              allData?.dimension?.catalogueDataMotor
                                ?.productImages?.length > 0
                                ? allData?.dimension?.catalogueDataMotor
                                    ?.productImages[0]?.url
                                : ''
                            }
                            alt={
                              allData?.dimension?.catalogueDataMotor
                                ?.productImages !== null
                                ? allData?.dimension?.catalogueDataMotor
                                    ?.productImages[0]?.url
                                : ''
                            }
                          />
                        </div>
                      )}
                    {!isLoading && (
                      <div className={'desc-container'}>
                        {allData?.dimension?.catalogueDataMotor
                          ?.productAttributes?.ProductName?.values[0]?.text && (
                          <div className="label-content">
                            <div className="label-title">
                              {'Product Name: '}
                            </div>
                            <div>
                              {' '}
                              {allData?.dimension?.catalogueDataMotor
                                ?.productAttributes?.ProductName?.values[0]
                                ?.text ?? '-'}
                            </div>
                          </div>
                        )}
                        <div id="desc-container">
                          {
                            allData?.dimension?.catalogueDataMotor
                              ?.productAttributes?.CatalogDescription?.values[0]
                              ?.text
                          }

                          {allData?.dimension?.catalogueDataMotor
                            ?.productAttributes?.ProductType?.values[0]
                            ?.text && (
                            <div className="label-content">
                              <div className="label-title">
                                {'Product Type: '}
                              </div>
                              <div>
                                {allData?.dimension?.catalogueDataMotor
                                  ?.productAttributes?.ProductType?.values[0]
                                  ?.text ?? '-'}
                              </div>
                            </div>
                          )}

                          {allData?.dimension?.catalogueDataMotor
                            ?.productAttributes?.FrameSize?.values[0]?.text && (
                            <div className="label-content">
                              <div className="label-title">
                                {'Frame Size: '}
                              </div>
                              <div>
                                {allData?.dimension?.catalogueDataMotor
                                  ?.productAttributes?.FrameSize?.values[0]
                                  ?.text ?? '-'}
                              </div>
                            </div>
                          )}

                          {allData?.dimension?.catalogueDataMotor
                            ?.productAttributes?.FrameMaterial?.values[0]
                            ?.text && (
                            <div className="label-content">
                              <div className="label-title">
                                {'Frame Material: '}
                              </div>
                              <div>
                                {allData?.dimension?.catalogueDataMotor
                                  ?.productAttributes?.FrameMaterial?.values[0]
                                  ?.text ?? '-'}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {allData?.dimension?.catalogueDataMotor?.productAttributes
                    ?.CatalogDescription?.values[0]?.text && (
                    <>
                      <div className="link-buttons-container">
                        <Button
                          type="discreet-black"
                          onClick={() => {
                            let searchItem = allData?.dimension?.dimensionResult
                              ?.supplyDriveLoads?.[0]?.motorUnit?.results[1]
                              ?.value
                              ? allData?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.motorUnit?.results[1]
                                  ?.value
                              : allData?.dimension?.dimensionResult
                                  ?.supplyDriveLoads?.[0]?.motorUnit?.results[0]
                                  ?.value;
                            window.open(
                              `https://new.abb.com/products/${searchItem}`,
                              '_blank',
                            );
                          }}
                        >
                          {
                            <Icon
                              slot="icon"
                              size="small"
                              name="open-in-new-window"
                            />
                          }
                          {'Open product page'}
                        </Button>
                        <Button
                          type="discreet-black"
                          className="view-catalog-button"
                          onClick={() =>
                            window.open(
                              allData?.dimension?.catalogueDataMotor
                                ?.catalogPDFURL,
                            )
                          }
                        >
                          {
                            <Icon
                              slot="icon"
                              size="small"
                              name="open-in-new-window"
                            />
                          }
                          {'View catalog'}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {result?.results && !isLoading && (
              <div className="result-notification-container">
                <Icon
                  slot="icon"
                  name="information-circle-1"
                  className="result-notification-ifno-icon"
                  size="small"
                />
                {'Losses [W]: 100% at base speed is equal to base load power'}
              </div>
            )}
            <div>
              <TabGroup
                selectedTabId="catalogueData"
                size="small"
                className="result-tabs"
              >
                <TabItem
                  label={t(I18.motor_selection_result_catalogue_data_label)}
                  closable={false}
                  tabId="catalogueData"
                  className="result-tab-item"
                ></TabItem>
                <TabItem
                  label={t(I18.motor_selection_result_selection_data_label)}
                  closable={false}
                  tabId="selectionData"
                  className="result-tab-item"
                  disabled={isLoading}
                ></TabItem>
                <TabItem
                  label={t(I18.motor_selection_result_vsd_rating_label)}
                  closable={false}
                  tabId="vsdRating"
                  className="result-tab-item"
                  disabled={isLoading}
                  data-rf="motor-selection-result-vsdRating-label"
                ></TabItem>
                <div slot="container">
                  <TabContent containerId={'catalogueData'}>
                    <MotorCatalogueData
                      catalogueTableData={catalogueTableData}
                      isLoading={isLoading}
                    />
                  </TabContent>
                  <TabContent containerId={'selectionData'}>
                    <table className="selection-data-table">
                      <thead>
                        <tr
                          className="selection-data-table-header-container"
                          key={generateKey('selectionData-header')}
                        >
                          <td></td>
                          <td>
                            {t(I18.motor_selection_result_required_label)}
                          </td>
                          <td>{t(I18.motor_selection_result_result_label)}</td>
                          <td>{t(I18.motor_selection_result_margin_label)}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectionData?.map((data, idx) => {
                          if (idx >= 0) {
                            return (
                              <tr
                                key={idx}
                                className="selection-data-table-row"
                              >
                                <th>{data.row[0]}</th>
                                <td>{data.row[1] ?? '-'}</td>
                                <td>{data.row[2] ?? '-'}</td>
                                <td>{data.row[3] ?? '-'}</td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </TabContent>
                  <TabContent
                    containerId={'vsdRating'}
                    data-rf="motor-selection-result-vsdRating"
                  >
                    <table className="vsdRating-table">
                      <thead className="vsdRating-table-header">
                        <tr>
                          <th></th>
                          <th>
                            {t(I18.motor_selection_result_min_speed_label)}
                          </th>
                          <th>
                            {t(I18.motor_selection_result_base_speed_label)}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="vsdRating-table-rows">
                        {vsdRatingData?.vSDData?.map((data, idx) => {
                          if (idx >= 0) {
                            return (
                              <tr
                                key={idx}
                                className="selection-data-table-row"
                              >
                                <th>{data.row[0]}</th>
                                <td>
                                  {data.row[2]
                                    ? data.row[2] + ' ' + data.row[1]
                                    : '-'}
                                </td>
                                <td>
                                  {data.row[3]
                                    ? data.row[3] + ' ' + data.row[1]
                                    : '-'}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </TabContent>
                </div>
              </TabGroup>
              {powerCurves &&
                torqueCurves &&
                Object.keys(torqueCurves).length > 0 && (
                  <div className="result-graph-container">
                    <div className="expand-graph-row">
                      <div className="graph-title">{'Graph'}</div>
                      <div
                        role="button"
                        className="expand-graph"
                        onClick={handleExpandGraphClick}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            e.preventDefault();
                            handleExpandGraphClick();
                          }
                        }}
                      >
                        <Icon slot="icon" size="small" name="pop-out-window" />
                      </div>
                    </div>

                    <RadioGroup
                      allowEmptySelection={false}
                      onRadioChange={(event) => {
                        handleRadioButtonClick(event);
                      }}
                      value={showPowerCurve ? 'power' : 'torque'}
                    >
                      <Radio
                        className="selection-radio"
                        name="torque"
                        size="small"
                        value="torque"
                      >
                        <span slot="label">Torque Curves</span>
                      </Radio>
                      <Radio
                        className="selection-radio"
                        name="power"
                        size="small"
                        value="power"
                      >
                        <span slot="label">Power Curves</span>
                      </Radio>
                    </RadioGroup>

                    {optionsRef && (
                      <div className="result-graph-container">
                        <ReactApexCharts
                          options={Object.assign({}, optionsRef, {
                            xaxis: {
                              min: 0,
                              max: parseFloat(
                                allData.dimension?.dimensionInput
                                  ?.selectionInputs?.[0]?.nMax ?? '0',
                              ),
                              tickAmount: 3,
                              title: {
                                text: 'Speed [rpm]',
                                style: {
                                  fontSize: '14px',
                                  fontWeight: 400,
                                },
                              },
                            },
                          })}
                          series={[
                            {
                              name: 'cont. load',
                              data: showPowerCurve
                                ? powerCurves.speedContLoad
                                : torqueCurves.speedContLoad,
                              color: '#000000',
                            },
                            {
                              name: 'cont. loadability',
                              data: showPowerCurve
                                ? powerCurves?.speedContLoadability
                                : torqueCurves?.speedContLoadability,
                              color: '#32cd32',
                            },
                            {
                              name: 'max. load',
                              data: showPowerCurve
                                ? powerCurves?.speedMaxLoad
                                : torqueCurves?.speedMaxLoad,
                              color: '#0000ff',
                            },
                            {
                              name: 'max. loadability',
                              data: showPowerCurve
                                ? powerCurves.speedMaxLoadability
                                : torqueCurves.speedMaxLoadability,
                              color: '#de4040',
                            },
                          ]}
                          type={'line'}
                          height={optionsRef?.chart?.height ?? 100}
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>

            <ResultGraphModalMotor
              modalOpen={expandGraph}
              onClose={onResultGraphModalClose}
              powerCurve={powerCurves}
              torqueCurve={torqueCurves}
              currentCurve={currentCurves}
              isOuterPowerSelect={showPowerCurve}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MotorSelectionResult;
