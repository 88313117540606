import React from 'react';
import Modal from '../../../components/Modal';
import { DeleteProjectModalProps } from './types';
import Icon from '../../../components/Icon';
import Button from '../../../components/Form/Button';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import CommonLoader from '../../../components/Loader';

const DeleteProjectModal = ({
  showModal,
  closeDeleteModal,
  projectName,
  deleteButtonHandler,
  deleteProjectLoading,
}: DeleteProjectModalProps) => {
  const { t } = useTranslate();

  return (
    <Modal
      open={showModal}
      onModalClose={closeDeleteModal}
      modalTitle={`${t(`delete_modal_title`)} ${projectName}`}
      showCloseButton={!deleteProjectLoading ? true : false}
      type="default"
      backdropClick={false}
      className="delete-project-modal"
      data-testid={`delete-project-${projectName}`}
    >
      <div slot="title-icon">
        <Icon name="trash" size="medium" />
      </div>

      <div className="delete-modal-container">
        <p data-testid="delete-modal-content">{t(`delete_modal_body`)}</p>
        <div className="delete-modal-footer">
          <Button
            type="discreet-black"
            onClick={(e) => {
              e.stopPropagation();
              closeDeleteModal();
            }}
            disabled={deleteProjectLoading}
            data-rf="project-cancel-button"
          >
            {t(`cancel_button`)}
          </Button>
          <Button
            type="primary-black"
            disabled={deleteProjectLoading}
            onClick={(e) => {
              e.stopPropagation();
              deleteButtonHandler();
            }}
            data-rf="project-delete-button"
          >
            {deleteProjectLoading ? (
              <div data-testid="delete-project-loader">
                <CommonLoader
                  indicatorType={'circular'}
                  progressType={'indeterminate'}
                  progressValue={50}
                  type={'primary-black'}
                  size="small"
                />
              </div>
            ) : (
              <div> {t(`delete_button`)}</div>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProjectModal;
