import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Form/Button';
import Icon from '../../../components/Icon';
import CommonLoader from '../../../components/Loader';
import ProjectsGridCard from '../../../components/ProjectsGridCard';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useTranslate } from '../../../i18translate/Hooks';
import { I18 } from '../../../languages/I18';
import { useSession } from '../../../utils/Auth';
import { generateKey, timeAgo } from '../../../utils/GeneralUtils';
import notificationManager from '../../../utils/NotificationManager';
import RecentProjectMenu from '../../Home/Components/RecentProjectMenu';
import { convertDateTime } from '../../Home/Components/RecentProjects';
import {
  CurrentProjectDetails,
  ProjectType,
} from '../../Home/models/homepageModel';
import { createProject } from '../../Home/store/actions/homeActions';
import { useDeleteProjectMutation } from '../../Workspace/Dimensioning/services/APIDimensioningService';
import { ProjectGridOrListViewProps } from '../models/ProjectsModel';
import DeleteProjectModal from '../../Home/Components/DeleteProjectModal';
import { RootState } from '../../../store/rootReducer';
import driveAndMotorIcon from './../../../../src/assets/images/drive-motor-small.svg';
import plcHmiIcon from './../../../../src/assets/images/cpu-io.svg';
import Cookies from 'js-cookie';

const ProjectGridOrListView = ({
  isListView,
  projects,
  fetchProjectDataAction,
  isLoading,
}: ProjectGridOrListViewProps) => {
  const projectTypeSelected = useSelector(
    (state: RootState) => state.home.projectTypeSelected,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteProject] = useDeleteProjectMutation();
  const { session } = useSession();
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);

  const handleProjectClick = (projectData: CurrentProjectDetails) => {
    sessionStorage.setItem('id', projectData.id);
    sessionStorage.setItem('name', projectData.name);
    if (projectData?.projectType === ProjectType.PLCHMI) {
      if (process.env.REACT_APP_PLC_CKNAME) {
        const date = new Date();
        Cookies.set(
          process.env.REACT_APP_PLC_CKNAME,
          date.getTime().toString(),
          {
            expires: 1,
            path: '/',
            domain: '.goselect.motion.abb.com',
          },
        );
      }
      window.location.href = process.env.REACT_APP_PLC_REDIRECT
        ? `${process.env.REACT_APP_PLC_REDIRECT}?id=${projectData?.id}`
        : '';
    } else {
      dispatch(
        createProject({
          projectId: projectData.id,
          projectName: projectData.name,
          projectType: projectTypeSelected,
        }),
      );
      navigate('/workspace');
    }
  };
  const { session: { user } = {} } = useSession();

  const initials = [
    user?.profile.given_name?.[0],
    user?.profile.family_name?.[0],
  ].filter((initial) => initial !== undefined);

  const deleteSelectedProject = async (project: CurrentProjectDetails) => {
    if (project.id) {
      setDeleteProjectLoading(true);
      await deleteProject({
        id: project.id,
        userToken: session?.user.access_token,
      })
        .then((deleteApiResponse) => {
          setDeleteProjectLoading(false);
          if ('data' in deleteApiResponse) {
            notificationManager.info(
              `${project.name} project has been deleted`,
            );
            fetchProjectDataAction();
          } else {
            notificationManager.error('Unable to delete the project');
          }
        })
        .catch((deleteApiError) => {
          setDeleteProjectLoading(false);
          notificationManager.error('Unable to delete the project');
        });
    }
  };

  const ListViewKebabMenu = ({ singleProject, deleteProjectLoading }: any) => {
    const [menu, setMenu] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const ref = useOutsideClick(() => {
      setMenu(false);
    });
    const { t } = useTranslate();

    return (
      <div style={{ position: 'relative' }} className="list-view">
        <Button
          type="discreet-black"
          onClick={() => setMenu(!menu)}
          data-rf="project-kebab-icon"
        >
          <Icon slot="icon" size="small" name="kebab" theme="dark" />
        </Button>
        <div ref={ref}>
          {menu ? (
            <div className="list-project-menu-container">
              <button
                onClick={() => handleProjectClick(singleProject)}
                data-rf="open-text-button"
              >
                <Icon name="folder-open" size="small" slot="menu-icon" />
                <label>{t(I18.open_button)}</label>
              </button>

              <button
                onClick={() => {
                  setShowDeleteModal(true);
                  setMenu(!menu);
                }}
                data-rf="delete-text-button"
              >
                <Icon name="trash" size="small" />
                <label>{t(I18.delete_button)}</label>
              </button>
            </div>
          ) : (
            ''
          )}
          <DeleteProjectModal
            closeDeleteModal={() => setShowDeleteModal(false)}
            showModal={showDeleteModal}
            projectName={singleProject.name}
            deleteButtonHandler={() => deleteSelectedProject(singleProject)}
            deleteProjectLoading={deleteProjectLoading}
          />
        </div>
      </div>
    );
  };

  const order = (a: any, b: any) => {
    return a.modifiedDate > b.modifiedDate
      ? -1
      : a.modifiedDate < b.modifiedDate
        ? 1
        : 0;
  };

  const allProjects = projects && projects?.slice().sort(order);

  return isLoading ? (
    <CommonLoader
      size="xlarge"
      indicatorType={'circular'}
      progressType={'indeterminate'}
      progressValue={50}
      type={'primary-black'}
    />
  ) : (
    <>
      {!isListView ? (
        allProjects?.map((project: CurrentProjectDetails, idx) => {
          return (
            <div className="projects-container project-page-grid-container">
              <ProjectsGridCard
                createdDate={project?.createdDate}
                modifiedDate={project?.modifiedDate}
                idx={idx}
                projectId={generateKey(project.id)}
                projectName={project.name}
                openProject={() => handleProjectClick(project)}
              >
                <RecentProjectMenu
                  deleteProjectButtonHandler={() =>
                    deleteSelectedProject(project)
                  }
                  projectName={project.name}
                  openProjectButtonHandler={() => handleProjectClick(project)}
                  deleteProjectLoading={deleteProjectLoading}
                  isProjectTypeGoSelect={
                    project?.projectType !== ProjectType.PLCHMI
                  }
                />
              </ProjectsGridCard>
            </div>
          );
        })
      ) : (
        <table className="project-listview-table">
          <tbody>
            <tr>
              <td>PROJECT NAME</td>
              <td>PROJECT TYPE</td>
              <td>LAST MODIFIED</td>
              <td>CREATED</td>
              <td>OWNER</td>
              <td></td>
            </tr>
            {allProjects?.map((project: CurrentProjectDetails, idx) => {
              return (
                <tr
                  key={generateKey(project.name + idx)}
                  data-rf={'list-view-project-' + (idx + 1)}
                >
                  <td
                    className="table-listview-project-name"
                    onClick={() => handleProjectClick(project)}
                  >
                    {project.name}
                  </td>
                  <td>
                    <div className="projectType-value-container">
                      <img
                        src={
                          project?.projectType !== ProjectType.PLCHMI
                            ? driveAndMotorIcon
                            : plcHmiIcon
                        }
                        alt="image-plc-him-or-drivemotoricon"
                      />
                      <div className="projectType-value-title">
                        {project?.projectType !== ProjectType.PLCHMI
                          ? 'Drive & Motor'
                          : 'PLC & HMI'}
                      </div>
                    </div>
                  </td>
                  <td>
                    {project?.modifiedDate
                      ? timeAgo.format(convertDateTime(project?.modifiedDate))
                      : '-'}
                  </td>
                  <td>
                    {project?.createdDate
                      ? timeAgo.format(convertDateTime(project?.createdDate))
                      : '-'}
                  </td>
                  <td>{initials}</td>
                  <td>
                    <ListViewKebabMenu
                      singleProject={project}
                      deleteProjectLoading={deleteProjectLoading}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ProjectGridOrListView;
